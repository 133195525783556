import React, { Component, Fragment } from 'react';
import { graphql } from 'gatsby';
import {
  Link,
  withTranslation,
  WithTranslation,
} from 'gatsby-plugin-react-i18next';
import { Redirect } from '@reach/router';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import Please from 'pleasejs';
import dayjs from 'dayjs';
import { FaInfoCircle, FaChartLine, FaHandPointRight } from 'react-icons/fa';
import slugify from 'slugify';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import 'dayjs/locale/fr';
import 'dayjs/locale/en';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);
dayjs.extend(weekOfYear);

ChartJS.register(ArcElement, Tooltip, Legend, Title);

/** Components */
import Seo from '@components/seo';
import MainContainer from '@components/main.container';
import { BlockLoader } from '@components/utils/block-loader';

/** Models */
import { BackResponse } from '@models/back-response.model';
import { DcaData, DcaProfileData } from '@models/dca-profile-data.model';

/** Root */
import { asyncWrap } from '@root/util';

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

type State = {
  userCode: string;
  data: DcaProfileData | undefined;
  loading: boolean;
  shouldBeRedirected: boolean;
  errorMessage: string;
};

class DcaProfileComponent extends Component<WithTranslation, State> {
  private readonly userCodeUrlParam = 'code';
  private readonly colorArray = [
    'blue',
    'coral',
    'darkorange',
    'olive',
    'royalblue',
    'chocolate',
    'crimson',
    'darkblue',
    'darkgreen',
    'darkmagenta',
    'blueviolet',
    'darkorchid',
    'deeppink',
    'gray',
    'indigo',
    'navy',
    'violet',
  ];

  constructor(props: Readonly<WithTranslation>) {
    super(props);

    this.state = {
      userCode: '',
      data: undefined,
      loading: true,
      shouldBeRedirected: false,
      errorMessage: '',
    };
  }

  componentDidMount(): void {
    const { search } = window.location;
    const searchParameters = new URLSearchParams(search);
    const rawBrowserLang = window.navigator.language;
    const locale = rawBrowserLang.split('-').shift() as string;

    dayjs.locale(locale);

    if (searchParameters.has(this.userCodeUrlParam)) {
      const userCode = searchParameters.get(this.userCodeUrlParam);

      if (userCode) {
        const slugifyReferralCode = slugify(userCode, {
          lower: true,
          replacement: '',
          strict: true,
        }).toUpperCase();

        this.setState({ userCode: slugifyReferralCode });
        this.getData(slugifyReferralCode);
      } else {
        this.setState({ shouldBeRedirected: true });
      }
    } else {
      this.setState({ shouldBeRedirected: true });
    }
  }

  private async getData(userCode: string): Promise<void> {
    this.setState({ loading: true });
    const requestDcaProfile = await asyncWrap<AxiosResponse, AxiosError>(
      axios({
        method: 'GET',
        url: `${process.env.GATSBY_API_URL}/dca/profile/${userCode}`,
      }),
    );

    if (requestDcaProfile.error) {
      const { t } = this.props;
      const error = requestDcaProfile.error;

      let errorMessage = t('global.errors.somethingWrong');

      if (error.isAxiosError && error.response) {
        const { data } = error.response as AxiosResponse<BackResponse>;

        errorMessage = data.message;
      }

      return this.setState({ errorMessage, loading: false });
    }

    const response = requestDcaProfile.result
      ?.data as BackResponse<DcaProfileData>;

    this.setState({ data: response.data, loading: false });
  }

  private getNextColor(): string {
    const baseColor = this.colorArray.shift();

    if (baseColor) {
      return Please.make_color({ base_color: baseColor, saturation: 0.2 })[0];
    }

    return Please.make_color({ saturation: 0.2 })[0];
  }

  private renderPieChart(): JSX.Element | null {
    const { data } = this.state;

    if (!data) {
      return null;
    }

    const backgroundColorArray = Object.keys(data.distributionPercentage).map(
      () => this.getNextColor(),
    );
    const pieData = {
      labels: Object.keys(data.distributionPercentage),
      datasets: [
        {
          data: Object.values<number>(data.distributionPercentage),
          backgroundColor: backgroundColorArray,
          hoverBackgroundColor: Object.keys(data.distributionPercentage).map(
            (value: string, index: number) =>
              Please.make_color({
                saturation: 0.5,
                base_color: backgroundColorArray[index],
              })[0],
          ),
        },
      ],
    };

    const { t } = this.props;
    const options = {
      plugins: {
        title: {
          display: true,
          text: t('dcaProfile.dcaDistribution'),
          position: 'bottom' as any,
        },
      },
    };

    return <Pie data={pieData} options={options} />;
  }

  private getEvolutionClassName(evolution: number): string {
    if (evolution > 0) {
      return 'fw-bold text-success p-3';
    }

    if (evolution < 0) {
      return 'fw-bold text-danger p-3';
    }

    return 'fw-bold text-warning p-3';
  }

  private getTimeZone(): string {
    let userTimeZone = dayjs.tz.guess();

    if (userTimeZone === 'CET') {
      userTimeZone = 'Europe/Paris';
    }

    return userTimeZone;
  }

  private renderDcaTable(): JSX.Element | null {
    const { data } = this.state;

    if (!data || !data.data.length) {
      return null;
    }

    const timeZone = this.getTimeZone();
    const dcaData = data.data;
    const hasAmount = dcaData.some((dca: DcaData) => !!dca.amount);
    const hasFirstOrder = dcaData.some((dca: DcaData) => !!dca.firstOrder);
    const hasLastOrder = dcaData.some((dca: DcaData) => !!dca.lastOrder);
    const { t } = this.props;

    return (
      <div className="table-responsive w-100">
        <table className="table table-hover table-borderless text-center">
          <thead className="table-dark">
            <tr>
              <th scope="col" className="p-3 rounded-start">
                {t('dcaProfile.token')}
              </th>
              <th scope="col" className="p-3">
                {t('dcaProfile.type')}
              </th>
              <th scope="col" className="p-3">
                {t('dcaSimulator.addDca.configureDca.recurrence')}
              </th>
              {hasFirstOrder && (
                <th scope="col" className="p-3">
                  {t('dcaProfile.firstOrder')}
                </th>
              )}
              {hasLastOrder && (
                <th scope="col" className="p-3">
                  {t('dcaProfile.lastOrder')}
                </th>
              )}
              {hasAmount ? (
                <Fragment>
                  <th scope="col" className="p-3">
                    {t('dcaProfile.amount')}
                  </th>
                  <th scope="col" className="p-3">
                    {t('dcaProfile.totalInvestedDollar')}
                  </th>
                  <th scope="col" className="p-3">
                    {t('dcaProfile.currentDollarValue')}
                  </th>
                </Fragment>
              ) : null}
              <th scope="col" className="p-3 rounded-end">
                {t('dcaProfile.evolution')}
              </th>
            </tr>
          </thead>
          <tbody>
            {dcaData.map((dca: DcaData) => {
              let evolutionStr = `${dca.evolution}`;

              if (dca.evolution > 0) {
                evolutionStr = `+${evolutionStr}`;
              }

              return (
                <tr key={dca.token}>
                  <td className="fw-bold p-3">{dca.token}</td>
                  <td className="p-3">
                    <span className="align-middle">
                      {t(`global.dcaType.${dca.type}`)}
                    </span>
                    {dca.type !== 'classic' ? (
                      <Link
                        className="ms-2 text-primary"
                        title={t('global.moreInfo')}
                        to="/"
                      >
                        <FaInfoCircle />
                      </Link>
                    ) : null}
                  </td>
                  <td className="p-3">{t(`dcaSimulator.${dca.interval}`)}</td>
                  {hasFirstOrder && (
                    <td className="p-3">
                      {dca.firstOrder
                        ? dayjs(dca.firstOrder).tz(timeZone).format('L')
                        : '-'}
                    </td>
                  )}
                  {hasLastOrder && (
                    <td className="p-3">
                      {dca.lastOrder
                        ? dayjs(dca.lastOrder).tz(timeZone).format('L')
                        : '-'}
                    </td>
                  )}
                  {hasAmount ? (
                    <Fragment>
                      <td className="p-3">{dca.amount} $</td>
                      <td className="p-3">{dca.totalDollarInvested} $</td>
                      <td className="p-3">{dca.currentDollarValue} $</td>
                    </Fragment>
                  ) : null}
                  <td className={this.getEvolutionClassName(dca.evolution)}>
                    {evolutionStr} %
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  private renderResult(): JSX.Element {
    const { errorMessage, userCode, data } = this.state;

    if (errorMessage.length || !data) {
      return (
        <div className="col-12 d-flex justify-content-center mt-5">
          <p className="alert alert-danger w-50 text-center mt-5">
            {errorMessage}
          </p>
        </div>
      );
    }

    const { t } = this.props;

    return (
      <Fragment>
        <h1 className="h2 text-primary fw-bold mt-5 pt-5 mb-0 mb-sm-3">
          {t('dcaProfile.title', {
            code: this.ucfirst(userCode.toLowerCase()),
          })}
        </h1>
        <div className="row mt-5">
          <div className="col-12 col-sm-3 d-flex justify-content-center align-items-center mb-4 mb-sm-0">
            {this.renderPieChart()}
          </div>
          <div className="col-12 col-sm-9 d-flex justify-content-center align-items-center">
            {this.renderDcaTable()}
          </div>
          <div className="col-12 mt-5 d-flex flex-sm-row-reverse">
            <section>
              <Link
                className="btn btn-primary text-body ps-5 pe-5 fw-bold"
                to="/dca-simulator"
              >
                <FaChartLine />
                <span className="ms-2 align-middle">
                  {t('home.dca.simulate')}
                </span>
              </Link>
              <Link
                className="btn btn-primary text-body ps-5 pe-5 fw-bold ms-0 ms-sm-2 mt-3 mt-sm-0"
                to={`/register?referralCode=${userCode}`}
              >
                <FaHandPointRight />
                <span className="ms-2 align-middle">
                  {t('dcaProfile.joinUs')}
                </span>
              </Link>
            </section>
          </div>
        </div>
      </Fragment>
    );
  }

  private ucfirst(str: string): string {
    return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
  }

  render(): JSX.Element {
    const { shouldBeRedirected, loading, userCode } = this.state;

    if (shouldBeRedirected) {
      return <Redirect to="/404" />;
    }

    const { t } = this.props;

    return (
      <Fragment>
        <Seo
          title={
            userCode.length
              ? t('dcaProfile.title', {
                  code: this.ucfirst(userCode.toLowerCase()),
                })
              : undefined
          }
          description={
            userCode.length
              ? t('seo.dcaProfile', {
                  code: this.ucfirst(userCode.toLowerCase()),
                })
              : undefined
          }
        />
        <MainContainer className="bg-custom-grey" classNameContainer="h-100">
          <div className="container-xxl dca-profile-height">
            <section className="row mb-5">
              {loading ? (
                <div className="mt-5 pt-5">
                  <BlockLoader
                    text={t('dcaProfile.loading')}
                    colorClassName="text-body"
                  />
                </div>
              ) : (
                this.renderResult()
              )}
            </section>
          </div>
        </MainContainer>
      </Fragment>
    );
  }
}

const DcaProfile = withTranslation()(DcaProfileComponent);

export default DcaProfile;
